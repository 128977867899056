let de = {
  "values": {
    "chess:q": "D",
    "chess:k": "K",
    "chess:r": "T",
    "chess:b": "L",
    "chess:n": "S",
    "chess:Q": "D",
    "chess:K": "K",
    "chess:R": "T",
    "chess:B": "L",
    "chess:N": "S",
    "buttons:flipper": "Tausche die Seiten",
    "buttons:first": "Gehe zum ersten Zug",
    "buttons:prev": "Gehe einen Zug zurück",
    "buttons:next": "Nächster Zug",
    "buttons:play": "Spiele alle Züge",
    "buttons:last": "Gehe ans Ende des Spiels",
    "buttons:deleteVar": "Lösche Variante",
    "buttons:promoteVar": "Werte Variante auf",
    "buttons:deleteMoves": "Lösche die restlichen Züge von hier",
    "buttons:nags": "NAGs Menü",
    "buttons:pgn": "Zeige die PGN Notation an",
    "buttons:hidePGN": "Ausblenden des angezeigten PGN",
    "nag:$1": "Starker Zug",
    "nag:$1_menu": "Guter Zug",
    "nag:$2": "Ein Fehler",
    "nag:$2_menu": "Fehler",
    "nag:$3": "Sehr starker Zug",
    "nag:$3_menu": "Sehr guter Zug",
    "nag:$4": "Ein grober Fehler",
    "nag:$4_menu": "Grober Fehler",
    "nag:$5": "Interessanter Zug",
    "nag:$5_menu": "Interessanter Zug",
    "nag:$6": "Zweifelhafter Zug",
    "nag:$6_menu": "Zweifelhafter Zug",
    "nag:$7": "Erzwungener Zug",
    "nag:$7_menu": "Erzwungener Zug",
    "nag:$8": "Einziger Zug",
    "nag:$8_menu": "Einziger Zug",
    "nag:$9": "Schlechtester Zug",
    "nag:$10": "Ausgeglichene Stellung",
    "nag:$10_menu": "Ausgeglichene Stellung",
    "nag:$11": "Ausgeglichene ruhige Stellung",
    "nag:$12": "Ausgeglichene aktive Stellung",
    "nag:$13": "Unklar",
    "nag:$13_menu": "Unklar",
    "nag:$14": "Weiß steht etwas besser",
    "nag:$14_menu": "Weiß steht etwas besser",
    "nag:$15": "Schwarz steht etwas besser",
    "nag:$15_menu": "Schwarz steht etwas besser",
    "nag:$16": "Weiß steht besser",
    "nag:$16_menu": "Weiß steht besser",
    "nag:$17": "Schwarz steht besser",
    "nag:$17_menu": "Schwarz steht besser",
    "nag:$18": "Weiß hat entscheidenden Vorteil",
    "nag:$18_menu": "Weiß gewinnt",
    "nag:$19": "Schwarz hat entscheidenden Vorteil",
    "nag:$19_menu": "Schwarz gewinnt",
    "nag:$20": "Weiß steht auf Gewinn.",
    "nag:$21": "Schwarz steht auf Gewinn.",
    "nag:$22": "Zugzwang Weiß",
    "nag:$22_menu": "Zugzwang",
    "nag:$23": "Zugzwang Schwarz",
    "nag:$24": "Weiß hat Raumvorteil",
    "nag:$24_menu": "Raumvorteil",
    "nag:$25": "Schwarz hat Raumvorteil",
    "nag:$26": "Weiß hat großen Raumvorteil",
    "nag:$27": "Schwarz hat großen Raumvorteil",
    "nag:$28": "Weiß hat entscheidenden Raumvorteil",
    "nag:$29": "Schwarz hat entscheidenden Raumvorteil",
    "nag:$30": "Weiß hat leichten Entwicklungsvorteil",
    "nag:$31": "Schwarz hat leichten Entwicklungsvorteil",
    "nag:$32": "Weiß hat moderaten Entwicklungsvorteil",
    "nag:$32_menu": "Entwicklungsvorteil",
    "nag:$33": "Schwarz hat moderaten Entwicklungsvorteil",
    "nag:$34": "Weiß hat entscheidenden Entwiclungsvorteil",
    "nag:$35": "Schwarz hat entscheidenden Entwiclungsvorteil",
    "nag:$36": "Weiß hat die Initiative",
    "nag:$36_menu": "Initiative",
    "nag:$37": "Schwarz hat die Initiative",
    "nag:$38": "Weiß hat entscheidende Initiative",
    "nag:$39": "Schwarz hat entscheidende Initiative",
    "nag:$40": "Weiß hat Angriff",
    "nag:$40_menu": "Angriffsvorteil",
    "nag:$41": "Schwarz hat Angriff",
    "nag:$42": "Weiß hat ungenügende Kompensation für das verlorene Material",
    "nag:$43": "Schwarz hat ungenügende Kompensation für das verlorene Material",
    "nag:$44": "Weiß hat genügende Kompensation für das verlorene Material",
    "nag:$44_menu": "Mit Kompensation",
    "nag:$45": "Schwarz hat genügende Kompensation für das verlorene Material",
    "nag:$46": "Weiß hat mehr als genügende Kompensation für das verlorene Material",
    "nag:$47": "Schwarz hat mehr als genügende Kompensation für das verlorene Material",
    "nag:$132_menu": "Gegenspiel",
    "nag:$136_menu": "Zeitnot",
    "nag:$140_menu": "Mit der Idee",
    "nag:$146_menu": "Neuheit"
  }
}

export default de;