let fr = {
    "values": {
        "buttons:flipper" : "Tourner l'échiquier",
        "buttons:first" : "Aller au premier coup",
        "buttons:prev" : "Coup précédent",
        "buttons:next" : "Coup suivant",
        "buttons:play" : "Jouer / arrêter tous les coups",
        "buttons:last" : "Aller au dernier coup",
        "buttons:deleteVar" : "Effacer la variation",
        "buttons:promoteVar" : "Promouvoir la variation",
        "buttons:deleteMoves" : "Effacer ce coup et tous les suivants",
        "buttons:nags" : "Menu NAGs",
        "buttons:pgn" : "Montrer le PGN de la partie",
        "buttons:hidePGN" : "Masquer le PGN affiché",
        "chess:q": "D",
        "chess:k": "R",
        "chess:r": "T",
        "chess:b": "F",
        "chess:n": "C",
        "chess:Q": "D",
        "chess:K": "R",
        "chess:R": "T",
        "chess:B": "F",
        "chess:N": "C",
        "nag:$0":	"pas d'annotation",
        "nag:$1":	"bon coup",
        "nag:$1_menu": "Bon coup",
        "nag:$2":	"mauvais coup",
        "nag:$2_menu": "Mauvais coup",
        "nag:$3":	"coup excellent",
        "nag:$3_menu": "Coup excellent",
        "nag:$4":	"très mauvais coup",
        "nag:$4_menu": "Très mauvais coup",
        "nag:$5":	"coup spéculatif",
        "nag:$5_menu": "Coup spéculatif",
        "nag:$6":	"coup douteux",
        "nag:$6_menu": "Coup douteux",
        "nag:$7":	"coup forcé (tous les autres coups perdent rapidement)",
        "nag:$7_menu": "Coup forcé",
        "nag:$8":	"seul coup (pas d'alternative raisonnable)",
        "nag:$8_menu": "Seul coup",
        "nag:$9":	"le plus mauvais coup",
        "nag:$10":	"position égale",
        "nag:$10_menu": "Position égale",
        "nag:$11":	"chances égales, position équilibrée",
        "nag:$12":	"chances égales, position dynamique",
        "nag:$13":	"position peu claire",
        "nag:$13_menu": "Position peu claire",
        "nag:$14":	"les Blancs ont un avantage minime",
        "nag:$14_menu": "Avantage minime blancs",
        "nag:$15":	"les Noirs ont un avantage minime",
        "nag:$15_menu": "Les Noirs ont un avantage minime",
        "nag:$16":	"les Blancs ont un avantage modéré",
        "nag:$17":	"les Noirs ont un avantage modéré"
    }
}

export default fr